.bmax {
  width: 300px;
}

.bmini {
  width: 200px;
}

.bmax2 {
  width: 300px;
}

.rp {
  margin-bottom: 10px;
}


.br {
  float: right;
}


.tk-btn{
    margin-bottom: 5px;

}

.exp-btn{
    margin-bottom: 5px;
}